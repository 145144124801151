import React, { useEffect, useRef } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/src/locomotive-scroll.scss'; // Import Locomotive Scroll styles
import './AboutPage.css'; // Import your custom styles
import OfficeImage from './content/OfficeImage.jpg';
import Your from './content/your1.png';
import New from './content/new1.png';
import You from './content/you1.png';
// import textimg from './content/textimg.svg';
// import lines from './content/lines.png';
import SampleVideo from './content/riovideo.mp4';
import Intro1 from './content/intro1.png';
import Intro2 from './content/intro2.png';
import Intro3 from './content/intro3.svg';
import SampleVideo1 from './content/officevideo.mp4';


function AboutPage() {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      direction: 'horizontal',
    });

    const handleScroll = (event) => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft += event.deltaY;
      }
    };

    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
      scroll.destroy();
    };
  }, []);

  return (
    <div className="about-page-container" data-scroll-container ref={scrollRef}>
      <div className="section" data-scroll-section>
        <img src={OfficeImage} alt="Office" className="office-image" />
      </div>
      <div className="section" data-scroll-section>

        <img src={Your} alt="Your" className="your-image" data-scroll data-scroll-speed="6" />
        <img src={New} alt="New" className="new-image" data-scroll data-scroll-speed="4" />
        <img src={You} alt="You" className="you-image" data-scroll data-scroll-speed="6" />


        <img src={Intro1} alt="Intro1" className="intro-1" data-scroll data-scroll-speed="4" />
        <img src={Intro2} alt="Intro1" className="intro-2" data-scroll data-scroll-speed="4" />

        <div className="video-section" data-scroll-section>
        <video className="video-element" loop autoPlay muted>
          <source src={SampleVideo} type="video/mp4" data-scroll data-scroll-speed="0"/>
          Your browser does not support the video tag.
        </video>

        </div>

        
        
        {/* <img src={lines} alt="lines" className="lines-image" data-scroll data-scroll-speed="3" /> */}
      </div>
      <div className="section" data-scroll-section>

      <img src={Intro3} alt="Intro1" className="intro-3" data-scroll data-scroll-speed="4" />

      <div className="video-section1" data-scroll-section>
        <video className="video-element1" loop autoPlay muted>
          <source src={SampleVideo1} type="video/mp4" data-scroll data-scroll-speed="0"/>
          Your browser does not support the video tag.
        </video>
      </div>

      </div>
    </div>
  );
}

export default AboutPage;
