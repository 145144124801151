import React, { useState, useEffect } from 'react';
import './InteractiveImageComponent.css';
import { useNavigate } from 'react-router-dom';

// Import default and small-screen images
import procedures from './procedures7.jpg';
import surgeries from './surgery7.jpg';
import smallProcedures from './768procedures.jpg';
import smallSurgeries from './768surgeries.jpg';

function InteractiveImageComponent() {
  const [currentImage, setCurrentImage] = useState(procedures);
  const [hoveredSection, setHoveredSection] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768); // Set initial state based on screen size
  const navigate = useNavigate();

  const sections = isSmallScreen
    ? [
        { name: 'PROCEDURES', image: smallProcedures },
        { name: 'SURGERIES', image: smallSurgeries },
      ]
    : [
        { name: 'PROCEDURES', image: procedures },
        { name: 'SURGERIES', image: surgeries },
      ];

  useEffect(() => {
    const handleResize = () => {
      const isNowSmallScreen = window.innerWidth <= 768;
      setIsSmallScreen(isNowSmallScreen);

      // Automatically set the appropriate default image when resizing
      if (!hoveredSection) {
        setCurrentImage(isNowSmallScreen ? smallProcedures : procedures);
      }
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize); // Listen for resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup
    };
  }, [hoveredSection]);

  const handleMouseEnter = (section) => {
    setCurrentImage(section.image);
    setHoveredSection(section.name);
  };

  const handleSectionClick = (section) => {
    window.scrollTo(0, 0);
    if (section.name === 'PROCEDURES') {
      navigate('/procedures');
    } else if (section.name === 'SURGERIES') {
      navigate('/surgeries');
    }
  };

  return (
    <div
      className="interactive-image-container"
      style={{ backgroundImage: `url(${currentImage})` }}
    >
      <div className="interactive-sections">
        {sections.map((section) => (
          <div
            key={section.name}
            onMouseEnter={() => handleMouseEnter(section)}
            onMouseLeave={() => setHoveredSection(null)}
            onClick={() => handleSectionClick(section)}
            style={{ opacity: hoveredSection === section.name ? 1 : 0 }}
          >
            {section.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default InteractiveImageComponent;

