import React, { useState, useEffect } from 'react';
import SurgeriesList from './SurgeriesList';
import SurgeryModal from './SurgeryModal';
import { surgeries } from './content/SurgeriesData';
import './SurgeriesPage.css';
import TitleImage from './content/TitleSur.png';


function SurgeriesPage() {
  const [isImageVisible, setIsImageVisible] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [selectedSurgery, setSelectedSurgery] = useState(null);


  useEffect(() => {
    const imageTimer = setTimeout(() => {
      setIsImageVisible(false);
    }, 3000);

    const contentTimer = setTimeout(() => {
      setIsContentVisible(true);
    }, 3000);

    return () => {
      clearTimeout(imageTimer);
      clearTimeout(contentTimer);
    };
  }, []);

  const handleSurgeryClick = (surgery) => {
    setSelectedSurgery(surgery);
  };

  const handleCloseModal = () => {
    setSelectedSurgery(null);
  };

  

  return (
    <div className="surgeries-page">
      <div className={`background-image ${isImageVisible ? 'visible' : 'fade-out'}`}></div>

      

      <div className={`content-wrapper ${isContentVisible ? 'visible' : ''}`}>
        <div className="title-image-container">
          <img src={TitleImage} alt="Title" className="title-image" />
        </div>
        <SurgeriesList surgeries={surgeries} onSurgeryClick={handleSurgeryClick} />
        {selectedSurgery && (
          <SurgeryModal surgery={selectedSurgery} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
}

export default SurgeriesPage;

