import React from 'react';
import './ProceduresList.css';

const ProceduresList = ({ procedures, onProcedureClick }) => {
  return (
    <div className="procedures-list">
      {procedures.map((procedure) => (
        <div 
          key={procedure.id} 
          className="procedure-card" 
          onClick={() => onProcedureClick(procedure)}
        >
          <img src={procedure.image} alt={procedure.name} className="procedure-image" />
          <div className="procedure-name">{procedure.name}</div>
        </div>
      ))}
    </div>
  );
};

export default ProceduresList;
