import React, { useState, useEffect } from 'react';
import './ComingSoon.css'; // Import the CSS for styling
import DesktopImage from './imclinic2.svg';
import MobileImage from './imclinic2-mobile.png'; // Import a smaller or different image for mobile screens

const ComingSoon = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Check if the screen width is less than or equal to 768px
    };

    handleResize(); // Run once on mount
    window.addEventListener('resize', handleResize); // Listen for window resize

    return () => window.removeEventListener('resize', handleResize); // Cleanup listener
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Email submitted');

    // Redirect the user to the desired link
    window.location.href = 'https://app.drjuansalazar.com/signup'; // Replace with your link
  };

  return (
    <div className="coming-soon-container">
      <img
        src={isMobile ? MobileImage : DesktopImage}
        className="Image-Clinic"
        alt="clinic"
      />
      <div className="coming-soon-container-1">
        <form onSubmit={handleSubmit} className="email-signup-form">
          <button type="submit">Sign Up</button>
        </form>
      </div>
    </div>
  );
};

export default ComingSoon;

