import React, { useState, useEffect } from 'react';
import ProceduresList from './ProceduresList';
import ProceduresModal from './ProceduresModal';
import { procedures } from './content/ProceduresData';
import './ProceduresPage.css';
import ProImage from './content/TitleProc.png';

function ProceduresPage() {
  const [isImageVisible, setIsImageVisible] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [selectedProcedure, setSelectedProcedure] = useState(null);

  useEffect(() => {
    const imageTimer = setTimeout(() => {
      setIsImageVisible(false);
    }, 3000);

    const contentTimer = setTimeout(() => {
      setIsContentVisible(true);
    }, 3000);

    return () => {
      clearTimeout(imageTimer);
      clearTimeout(contentTimer);
    };
  }, []);

  const handleProcedureClick = (procedure) => {
    setSelectedProcedure(procedure);
  };

  const handleCloseModal = () => {
    setSelectedProcedure(null);
  };

  return (
    <div className="procedures-page">
      <div className={`procedure-background-image ${isImageVisible ? 'visible' : 'fade-out'}`}></div>
      
      <div className={`pro-content-wrapper ${isContentVisible ? 'visible' : ''}`}>
        <div className="pro-title-image-container">
          <img src={ProImage} alt="ProTitle" className="pro-title-image" />
        </div>
        <ProceduresList procedures={procedures} onProcedureClick={handleProcedureClick} />
        {selectedProcedure && (
          <ProceduresModal procedure={selectedProcedure} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
}

export default ProceduresPage;
