export const procedures = [
  { 
    id: 1, 
    name: 'Botox', 
    content: `
      Botox is a quick, minimally invasive treatment that reduces wrinkles by relaxing facial muscles. It’s also used medically for conditions like migraines and excessive sweating.

Procedure: Small injections are administered in 15-30 minutes with no downtime.

Post-Treatment Care:

First 24 Hours: Avoid pressure on treated areas and intense activities.
After 7-14 Days: Full results are visible and last 3-6 months.
Botox offers a safe, effective way to achieve a youthful look or treat medical issues with minimal recovery.
      `, 
    image: './proceduresfolder/Botox.jpg' 
  },
  { id: 2, name: 'Laser', content: `
      Laser treatments are advanced, non-invasive procedures used to improve skin texture, tone, and appearance. They effectively address concerns like wrinkles, scars, pigmentation, and unwanted hair.

Common Applications:

Skin resurfacing for wrinkles and scars.
Treating hyperpigmentation and sun damage.
Hair removal.
Procedure: Sessions typically last 15-60 minutes, depending on the area treated. Minimal downtime is required.

Post-Treatment Care:

Avoid sun exposure and apply sunscreen.
Mild redness or swelling resolves within a few days.
Laser treatments provide precise, effective results for various skin concerns with minimal recovery time.
      `, image: './proceduresfolder/Laser.jpg' },
  { id: 3, name: 'Collagen Biostimulators (Sculptra / Radiesse)', content: `
      Collagen biostimulators are injectable treatments that restore volume, improve skin elasticity, and stimulate natural collagen production for long-lasting rejuvenation.

How They Work:

Sculptra: Gradually restores volume by stimulating collagen over time, ideal for overall facial rejuvenation.
Radiesse: Provides immediate volume and stimulates collagen for sustained skin improvement.
Procedure:
Performed in 30-60 minutes with little to no downtime. Results develop gradually and last up to 18-24 months.

Post-Treatment Care:

Massage treated areas as advised to distribute the product evenly.
Avoid intense physical activity for 24 hours.
Collagen biostimulators offer a natural, long-lasting solution to aging concerns, enhancing skin health and appearance over time.
      `, image: './proceduresfolder/Biostimulators.jpg' },
  { id: 4, name: 'Hand Rejuvenation (Radiesse)', content: `
      Hand rejuvenation treatments restore volume, improve skin texture, and reduce visible signs of aging such as wrinkles, sunspots, and prominent veins.

Common Treatments:

Dermal Fillers: Restore lost volume and smooth wrinkles.
Laser Therapy: Reduces pigmentation and improves skin texture.
Chemical Peels: Lighten sunspots and enhance skin tone.
Procedure:
Minimally invasive, lasting 30-60 minutes. Most treatments require little to no downtime.

Post-Treatment Care:

Protect hands from sun exposure with sunscreen.
Follow specific aftercare instructions for optimal results.
Hand rejuvenation delivers youthful, natural-looking hands with minimal recovery time.
      `, image: './proceduresfolder/HandRejuvenation.jpg' },
  { id: 5, name: 'Ultraformer', content: `
      Ultraformer is a non-invasive treatment that uses high-intensity focused ultrasound (HIFU) to tighten skin, reduce wrinkles, and lift facial and body contours. It stimulates collagen production, offering a natural, youthful appearance.

Common Applications:

Facial lifting and tightening.
Body contouring and skin firming.
Procedure:
A session lasts 30-60 minutes with no downtime. Mild redness or swelling may occur but resolves quickly.

Results:
Visible improvements appear gradually over 2-3 months and last up to 12-18 months.

Ultraformer is an effective, non-surgical option for achieving tighter, rejuvenated skin with long-lasting results.
      `, image: './proceduresfolder/Ultraformer.jpg' },
  { id: 6, name: 'Hyaluronic Acid', content: `
      Hyaluronic acid fillers are injectable treatments used to restore volume, smooth wrinkles, and enhance facial contours. HA is a naturally occurring substance in the skin that attracts and retains moisture for a hydrated, youthful look.

Common Uses:

Lip enhancement.
Filling nasolabial folds and marionette lines.
Contouring cheeks, jawline, and under-eye hollows.
Procedure:
Quick and minimally invasive, lasting 15-30 minutes with immediate results and little to no downtime.

Post-Treatment Care:

Avoid intense physical activity for 24 hours.
Mild swelling or bruising may occur and subsides within a few days.
Hyaluronic acid fillers provide natural, instant, and reversible results, making them a popular choice for facial rejuvenation.
      `, image: './proceduresfolder/HyaluronicAcid.jpg' },
  
  { id: 7, name: 'Botox Hyperhidrosis (Axillary)', content: `
      Hyaluronic acid fillers are injectable treatments used to restore volume, smooth wrinkles, and enhance facial contours. HA is a naturally occurring substance in the skin that attracts and retains moisture for a hydrated, youthful look.

Common Uses:

Lip enhancement.
Filling nasolabial folds and marionette lines.
Contouring cheeks, jawline, and under-eye hollows.
Procedure:
Quick and minimally invasive, lasting 15-30 minutes with immediate results and little to no downtime.

Post-Treatment Care:

Avoid intense physical activity for 24 hours.
Mild swelling or bruising may occur and subsides within a few days.
Hyaluronic acid fillers provide natural, instant, and reversible results, making them a popular choice for facial rejuvenation.
      `, image: './proceduresfolder/Hyperhidrosis.jpg' },
  { id: 8, name: 'Lip Fillers', content: `
      Lip fillers are a minimally invasive treatment designed to enhance lip volume, shape, and definition. Using hyaluronic acid-based fillers, this procedure provides a natural, plump appearance while hydrating the lips.

Procedure:

Takes 15-30 minutes with minimal discomfort.
Results are immediate, with slight swelling that subsides within a few days.
Post-Treatment Care:

Avoid hot beverages, intense physical activity, and lip pressure for 24 hours.
Use cold compresses to reduce swelling if needed.
Results:

Lasts 6-12 months, depending on metabolism and product used.
Lip fillers provide a quick, effective way to achieve fuller, well-defined lips with a natural look and minimal downtime.
      `, image: './proceduresfolder/LipFillers.jpg' },
  { id: 9, name: 'Facial Harmonization', content: `
      Facial harmonization is a customized approach to enhance facial balance and proportions using non-surgical procedures. It combines dermal fillers, Botox, and other treatments to achieve a youthful and naturally symmetrical appearance.

Common Areas Treated:
Forehead and Brow: Smooth wrinkles and lift brows.
Cheeks: Add volume for a youthful contour.
Nose: Non-surgical rhinoplasty for minor adjustments.
Chin and Jawline: Define and balance lower face proportions.
Lips: Enhance shape and volume.
Procedure:

Performed in 30-60 minutes with minimal discomfort.
Immediate results with little to no downtime.
Post-Treatment Care:

Avoid strenuous activity and facial pressure for 24 hours.
Mild swelling or bruising resolves within a few days.
Results:

Long-lasting, natural enhancements that typically last 12-18 months.
Facial harmonization creates a balanced, refreshed look tailored to each individual’s features, delivering subtle yet impactful results.
      `, image: './proceduresfolder/FacialHarmonization.jpg' },
  { id: 10, name: 'Skinbooster (Periocular/Perioral)', content: `
      Skinboosters are injectable treatments designed to deeply hydrate and improve the texture and elasticity of the skin. They target fine lines and dryness around delicate areas such as the eyes (periocular) and mouth (perioral), providing a rejuvenated and refreshed appearance.

Benefits:
Hydrates from within for plumper, smoother skin.
Reduces fine lines and wrinkles.
Enhances skin elasticity and glow.
Procedure:

Takes 20-40 minutes with minimal discomfort.
Injections are placed just beneath the skin in the targeted areas.
Post-Treatment Care:

Mild swelling or redness may occur and resolves within 1-2 days.
Avoid makeup and intense activities for 24 hours.
Results:

Visible improvement in skin quality after 1-2 weeks.
Long-lasting effects, with results typically lasting 6-12 months.
Skinboosters offer a subtle, natural way to rejuvenate and hydrate the periocular and perioral areas, enhancing overall skin health and appearance.
      `, image: './proceduresfolder/Skinbooster.jpg' },
  { id: 11, name: 'Microneedling', content: `
      Microneedling is a minimally invasive treatment that boosts collagen, improving skin texture, scars, wrinkles, and pores.

Procedure:

30-60 minutes; numbing cream may be applied.
Can be combined with serums or PRP.
Post-Care:

Redness for 1-2 days; avoid sun and harsh products.
Hydrate and use sunscreen.
Results:

Gradual improvement over weeks, lasting effects with multiple sessions.
Microneedling rejuvenates skin naturally, leaving it smoother and firmer.
      `, image: './proceduresfolder/Microneedling.jpg' },
  { id: 12, name: 'Chemical Peel', content: `
      A chemical peel is a skin-resurfacing treatment that uses a chemical solution to exfoliate and remove damaged skin layers, improving texture, tone, and radiance.

Benefits:

Reduces fine lines, acne scars, and pigmentation.
Improves skin texture and brightness.
Procedure:

Quick, lasting 15-30 minutes.
Mild peels require no downtime, while deeper peels may need recovery time.
Post-Care:

Avoid sun exposure and use sunscreen.
Peeling and redness may occur for a few days.
Results:

Smoother, clearer skin visible after healing.
Chemical peels offer an effective way to rejuvenate and refresh the skin with minimal downtime.
      `, image: './proceduresfolder/ChemicalPeel.jpg' },

  { id: 13, name: 'Therapeutic Botulinum Toxin (Migraine/Bruxism)', content: `
      Botulinum toxin is an effective treatment for medical conditions like migraines and bruxism (teeth grinding). It relaxes targeted muscles, reducing pain and improving quality of life.

Benefits:
Migraines: Reduces frequency and severity by targeting muscle tension and nerve activation.
Bruxism: Relieves jaw pain and protects teeth from grinding damage.
Procedure:

Quick and minimally invasive, lasting 15-30 minutes.
Injections are strategically placed in the head, neck, or jaw muscles.
Post-Treatment Care:

Avoid strenuous activity for 24 hours.
Results appear within 1-2 weeks and last 3-6 months.
Botulinum toxin provides safe, long-lasting relief for migraines and bruxism, improving comfort and daily function.
      `, image: './proceduresfolder/BotulinumToxin.jpg' },
  
];
