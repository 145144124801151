import React from 'react';
import './Footer.css';
import { ReactComponent as Logo } from './logofooter.svg'; // Import your logo as a React component
import { ReactComponent as InstagramIcon } from './instagram-icon.svg';
import { ReactComponent as WhatsappIcon } from './whatsapp-icon.svg';
import { ReactComponent as FacebookIcon } from './facebook-icon.svg'; // Add your Facebook icon
import { ReactComponent as TiktokIcon } from './tiktok-icon.svg'; // Add your TikTok icon

function Footer() {
    return (
        <footer className="App-footer">
            <div className="footer-row footer-top">
                <Logo className="footer-logo" />
                <div className="footer-social-contact">
                    <div className="social-icons">
                        <a href="https://www.facebook.com/david.castro.56679/" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon className="social-icon" />
                        </a>
                        <a href="https://www.instagram.com/dr.juanslzar/" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon className="social-icon" />
                        </a>
                        
                    </div>
                </div>
                <div className="footer-row footer-address">
                    <span>Almirante Guilhen 366 Leblon 4 andar</span>
                </div>
                <div className="footer-contact">
                    <a href="mailto:contacto@juansalazar.com">contacto@juansalazar.com</a>
                </div>
            </div>
            <div className="footer-row footer-privacy">
                <a href="/privacy-policy">Privacy Policy</a>
            </div>
            <div className="footer-row footer-bottom">
                <span>Clinica Dr. Juan Salazar © Powered by Felipe Castro </span>
            </div>

            <div className="footer-social-contact2">
                    <div className="social-icons2">
                        <a href="https://www.tiktok.com/@dr.juanslzar" target="_blank" rel="noopener noreferrer">
                            <TiktokIcon className="social-icon" />
                        </a>
                        <a href="http://wa.link/kwzdce" target="_blank" rel="noopener noreferrer">
                            <WhatsappIcon className="social-icon" />
                        </a>
                    </div>
                </div>
        </footer>
    );
}

export default Footer;
