import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './DropdownMenu.css';
import MenuIcon from './Smenu6.svg';
import MenuImage from './menu-image.png'; // Replace with your image path

function DropdownMenu() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dropdownRef = useRef(null);

  // Function to close the dropdown (memoized)
  const closeDropdown = useCallback(() => {
    setIsDropdownOpen(false);
    document.body.style.overflow = ''; // Enable scrolling
  }, []);

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => {
      if (isMobile && !prev) {
        document.body.style.overflow = 'hidden'; // Disable scrolling for mobile
      } else {
        document.body.style.overflow = ''; // Enable scrolling
      }
      return !prev;
    });
  };

  // Update isMobile dynamically on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = ''; // Reset overflow on component unmount
    };
  }, [closeDropdown]);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <img
        src={MenuIcon}
        className="dropdown-logo"
        alt="Menu"
        onClick={toggleDropdown}
      />
      {isDropdownOpen && (
        <div className="dropdown-content">
          {/* Close button for mobile */}
          {isMobile && (
            <button className="close-btn" onClick={closeDropdown}>
              &times;
            </button>
          )}
          <Link to="/" className="dropdown-link" onClick={closeDropdown}>
            HOME
          </Link>
          <Link to="/about" className="dropdown-link" onClick={closeDropdown}>
            ABOUT
          </Link>
          <Link to="/procedures" className="dropdown-link" onClick={closeDropdown}>
            PROCEDURES
          </Link>
          <Link to="/surgeries" className="dropdown-link" onClick={closeDropdown}>
            SURGERIES
          </Link>
          <a
            href="https://app.drjuansalazar.com/login"
            className="dropdown-link"
            rel="noopener noreferrer"
            target="_blank"
            onClick={closeDropdown}
          >
            LOGIN
          </a>
          <Link to="/language" className="dropdown-link" onClick={closeDropdown}>
            EN
          </Link>
          {/* Image below the menu options */}
          <img src={MenuImage} className="menu-image" alt="Menu visual" />
        </div>
      )}
    </div>
  );
}

export default DropdownMenu;
