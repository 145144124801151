import React from 'react';
import './ProceduresModal.css';

const ProceduresModal = ({ procedure, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <div className="modal-body">
          <div className="modal-text">
            <h2>{procedure.name}</h2>
            <p>{procedure.content.split('\n').map((line, index) => (
              <span key={index}>{line}<br /></span>
            ))}</p>
            <button className="book-button">Book Appointment</button>
          </div>
          <div className="modal-image-container">
            <img src={procedure.image} alt={procedure.name} className="modal-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProceduresModal;

