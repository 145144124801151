import React from 'react';
import './SurgeriesList.css';

const SurgeriesList = ({ surgeries, onSurgeryClick }) => {
  return (
    <div className="surgeries-list">
      {surgeries.map((surgery) => (
        <div 
          key={surgery.id} 
          className="surgery-card" 
          onClick={() => onSurgeryClick(surgery)}
        >
          <img src={surgery.image} alt={surgery.name} className="surgery-image" />
          <div className="surgery-name">{surgery.name}</div>
        </div>
      ))}
    </div>
  );
};

export default SurgeriesList;
