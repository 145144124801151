import React, { useState, useEffect } from 'react';
import './SurgeonProfile.css';
import SurgeonPhoto from './surgeon6.png';
import ClinicLogo from './clinic-logo.svg';
import { useNavigate } from 'react-router-dom';

function SurgeonProfile() {
  const [offsetY, setOffsetY] = useState(0);
  const maxOffsetY = 800;  // Maximum offset for background to stop moving
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const handleScroll = () => {
      const newOffsetY = Math.min(window.pageYOffset, maxOffsetY);  // Cap the offsetY at maxOffsetY
      setOffsetY(newOffsetY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [maxOffsetY]);

  const backgroundStyle = {
    transform: `translateY(${offsetY * 0.3}px)`,  // Adjust the speed of the background movement here
  };

  return (
    <div className="SurgeonProfile">
      <div className="SurgeonProfile-background" style={backgroundStyle} />
      <div className="SurgeonProfile-content">
        <div className="SurgeonProfile-info">
          <img src={ClinicLogo} alt="Clinic Logo" className="Clinic-logo" />
          <p className="Surgeon-description">
          Dr. Juan David Salazar is a distinguished board-certified Plastic and Reconstructive Surgeon committed to improving patients' well-being and self-esteem. He possesses a unique ability to personalize the most contemporary surgical techniques, providing harmonious, balanced, and natural results for the face, breasts, and body.
Originally from Colombia, Dr. Salazar discovered his passion for medicine while being surrounded by doctors in his family. This inspired him to pursue an international career in plastic surgery.
          </p>
        </div>
        <div className="SurgeonProfile-image">
          <img src={SurgeonPhoto} alt="Surgeon" />
        </div>
        <div className="button-about">
        <button className="button-about-btn" onClick={() => navigate('/about')}>More</button> 
        </div>
      </div>
    </div>
  );
}

export default SurgeonProfile;
