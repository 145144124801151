import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';
import './App.css';
import DropdownMenu from './DropdownMenu';
import Logo from './LOGOjuandavid2.svg';
import Footer from './Footer';
import HomePage from './HomePage';
import AboutPage from './pages/AboutPage';
import ProceduresPage from './pages/ProceduresPage';
import SurgeriesPage from './pages/SurgeriesPage';
import ComingSoon from './ComingSoon';
// import useScrollToTop from './useScrollToTop';

function App() {
  const [clientLoggedIn, setClientLoggedIn] = useState(false);

  const handleClientLogin = () => {
    const passwordPrompt = prompt("Enter the client password:");
    if (passwordPrompt === 'castrosalazar0208') {
      setClientLoggedIn(true);
    } else {
      alert("Incorrect password.");
    }
  };

  // useEffect(() => {
  //   if (isMobile) {
  //     window.location.href = 'https://linktr.ee/juansalazarplasticsurgery';
  //   }
  // }, []);

  if (!clientLoggedIn) {
    return (
      <div className="coming-soon-container">
        <ComingSoon />
        <button onClick={handleClientLogin} className="client-login-button">Login</button>
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/">
            <img src={Logo} className="App-logo" alt="logo" />
          </Link>
          <DropdownMenu />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/procedures" element={<ProceduresPage />} />
            <Route path="/surgeries" element={<SurgeriesPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

