export const surgeries = [
    { 
      id: 1, 
      name: 'Face Lifting', 
      content: `
      As we age, facial changes due to skin laxity, fat atrophy, muscle action, and bone resorption lead to a tired appearance.

      Facelift surgery (rhytidectomy) addresses these by repositioning internal structures and skin, restoring a youthful look. We often perform fat grafting, rich in stem cells, to enhance facial depressions and skin quality.
      
      A facelift can be combined with blepharoplasty, mentoplasty, and CO2 fractional laser. General anesthesia or sedation ensures tolerance to high-power laser treatments.
      
      Surgery: Typically performed under local anesthesia with sedation. Patients usually stay overnight.
      
      Post-Operative Care:
      
      First Week: Stitches removed from day 7.
      After 20 Days: Resume daily activities.
      After 30 Days: Lower body physical activities permitted.
      After 60 Days: Upper body physical activities allowed.
      Our goal is a smooth recovery and comprehensive care for the best results.
      `, 
      image: './surgeriesfolder/Face_Lifting.jpg' 
    },
    { id: 2, name: 'Rhinoplasty', content: `
      Rhinoplasty, commonly referred to as a "nose job," is a surgical procedure designed to improve the shape, size, or functionality of the nose. It addresses issues like nasal asymmetry, hump reduction, tip refinement, or breathing difficulties due to structural abnormalities.

The surgery reshapes nasal cartilage and bone to achieve a balanced and harmonious facial appearance while maintaining or enhancing nasal functionality. Advanced techniques ensure natural-looking results tailored to each patient's unique features.

Rhinoplasty can be combined with septoplasty to correct breathing problems or with chin augmentation for profile harmonization. Procedures are performed under general anesthesia for patient comfort.

Surgery: Usually completed within 1-3 hours. Patients may go home the same day or stay overnight if required.

Post-Operative Care:

First Week: Splints and stitches are removed around day 7. Bruising and swelling begin to subside.
After 14 Days: Most visible swelling resolves, and patients can return to light social activities.
After 30 Days: Nose taping at night may continue to support healing. Avoid strenuous activities.
After 6 Months to 1 Year: Final results are fully apparent as swelling completely subsides.
Our aim is to achieve a natural, refined nose that complements your facial features while prioritizing your safety and satisfaction.
      `, image: './surgeriesfolder/Rhinoplasty.jpg' },
    { id: 3, name: 'Blepharoplasty', content: `
      Blepharoplasty, or eyelid surgery, is a procedure designed to rejuvenate the appearance of the eyes by addressing sagging upper eyelids, under-eye bags, or excess skin and fat around the eyes. It enhances the natural contours of the eyes, reducing a tired or aged look.

The surgery involves precise removal or repositioning of fat and skin to restore a refreshed and youthful appearance. This procedure can also improve vision if drooping eyelids obstruct sight. Upper and lower blepharoplasty can be performed individually or together, depending on patient needs.

Surgery: Performed under local anesthesia with sedation or general anesthesia. The procedure typically lasts 1-2 hours, with no need for an overnight stay in most cases.

Post-Operative Care:

First Week: Initial swelling and bruising reduce significantly. Stitches are usually removed around day 5-7. Cold compresses can help minimize swelling.
After 10-14 Days: Most patients feel comfortable resuming social and professional activities. Makeup can be applied to conceal any residual bruising.
After 30 Days: Patients can resume light physical activities, avoiding direct sun exposure to scars.
After 3 Months: Scars become less noticeable, and the final results are visible as tissues settle.
Our approach focuses on achieving a naturally refreshed appearance, tailored to each patient's anatomy, while ensuring a smooth and comfortable recovery.
      `, image: './surgeriesfolder/Blepharoplasty.jpg' },
    { id: 4, name: 'Otoplasty', content: `
     Otoplasty, commonly known as ear surgery, is a cosmetic procedure that reshapes or repositions the ears to achieve a more balanced appearance. It is often performed to correct prominent ears, asymmetry, or deformities caused by injury or congenital conditions.

The procedure typically involves sculpting or removing cartilage to create a natural contour, allowing the ears to lie closer to the head. Otoplasty can enhance self-confidence and is suitable for both children and adults once ear development is complete (usually around age 5-7).

Surgery: Usually performed under local anesthesia with sedation or general anesthesia, depending on the patient’s age and preference. The surgery takes about 1-2 hours, and patients can return home the same day.

Post-Operative Care:

First Week: A headband or bandages are worn continuously to protect the ears and support healing. Initial swelling and discomfort subside.
After 10-14 Days: Bandages are removed, and most patients can resume daily activities, avoiding contact sports or pressure on the ears.
After 30 Days: Light physical activities are allowed. Ear sensitivity decreases gradually.
After 3-6 Months: Final results are apparent, and scars fade, becoming nearly invisible.
Our goal with otoplasty is to create natural, symmetrical results that enhance your overall facial harmony while ensuring a safe and comfortable recovery process.
      `, image: './surgeriesfolder/Otoplasty.jpg' },
    { id: 5, name: 'Mentoplasty', content: `
     Mentoplasty, also known as chin surgery or chin augmentation, is a procedure designed to enhance the contours of the chin, improving facial balance and harmony. It can involve the placement of an implant to increase chin projection or a reduction technique to reshape the chin bone for a softer appearance.

This surgery is ideal for patients with a recessed chin, prominent chin, or asymmetry. Mentoplasty can be performed alone or combined with other facial procedures, such as rhinoplasty, to achieve a more harmonious profile.

Surgery: Performed under local anesthesia with sedation or general anesthesia. The procedure typically takes 1-2 hours, and patients can return home the same day.

Post-Operative Care:

First Week: Swelling and bruising are common but manageable. Stitches are typically removed by day 7, if non-dissolvable. A chin strap may be recommended for support.
After 14 Days: Most swelling subsides, and patients can return to social and professional activities.
After 30 Days: Light physical activities are permitted, but avoid heavy lifting or direct pressure on the chin area.
After 3-6 Months: Final results become apparent as swelling fully resolves, with scars fading into the natural contours of the chin.
Mentoplasty offers a refined, balanced appearance that complements your natural features, boosting confidence and ensuring a smooth recovery tailored to your needs.
      `, image: '../surgeriesfolder/Mentoplasty.jpg' },
    { id: 6, name: 'Breast Augmentation', content: `
     Breast augmentation, also known as augmentation mammoplasty, is a surgical procedure designed to enhance the size, shape, and symmetry of the breasts. It involves the placement of implants (silicone or saline) or fat transfer to achieve a fuller, more proportional appearance.

This procedure is ideal for individuals seeking to restore volume after weight loss, pregnancy, or aging, or for those who desire a more balanced silhouette. Each augmentation is tailored to the patient’s anatomy and aesthetic goals to ensure natural-looking results.

Surgery: Performed under general anesthesia, the procedure typically lasts 1-2 hours. Patients can usually go home the same day.

Post-Operative Care:

First Week: Swelling and discomfort are managed with prescribed medications. A surgical bra is worn to support the breasts. Most patients can resume light activities within a few days.
After 2 Weeks: Stitches, if non-dissolvable, are removed. Swelling and bruising continue to subside, and patients can return to non-strenuous work.
After 4-6 Weeks: Physical activities, including exercise, can be gradually resumed with approval from the surgeon.
After 3-6 Months: Final results are visible as implants settle, and swelling fully resolves. Scars fade over time and become less noticeable.
Breast augmentation enhances your confidence and helps achieve the aesthetic goals you envision, with a focus on safety and personalized care for optimal results.
      `, image: './surgeriesfolder/Breast_Prosthesis.jpg' },
    { id: 7, name: 'Breast Reduction', content: `
     Breast reduction, or reduction mammoplasty, is a surgical procedure designed to reduce the size and weight of overly large breasts. It alleviates physical discomfort such as back, neck, and shoulder pain, and improves posture and mobility, while also enhancing the aesthetic proportions of the chest.

The procedure involves removing excess breast tissue, fat, and skin, followed by reshaping and lifting the breasts for a more youthful and balanced appearance. It is ideal for individuals experiencing discomfort or self-consciousness due to large breasts.

Surgery: Performed under general anesthesia, the procedure typically takes 2-4 hours. Most patients stay overnight for observation.

Post-Operative Care:

First Week: Swelling, bruising, and mild discomfort are common. Patients wear a surgical bra to support the breasts. Stitches may be dissolvable or removed after 7-10 days.
After 2 Weeks: Swelling begins to subside, and most patients can return to light work and daily activities.
After 4-6 Weeks: Physical activities, including exercise, can be gradually resumed.
After 3-6 Months: Final results are visible as swelling resolves and scars fade. The breasts settle into their natural shape and position.
Breast reduction improves physical comfort, enhances mobility, and provides a more proportionate appearance, empowering you to feel confident and comfortable in your body.
      `, image: './surgeriesfolder/Breast_Reduction.jpg' },
    { id: 8, name: 'Breast Lift', content: `
     A breast lift, or mastopexy, is a surgical procedure designed to elevate and reshape sagging breasts, restoring a youthful and firmer appearance. It addresses issues such as stretched skin, loss of volume, and downward-pointing nipples caused by aging, pregnancy, weight loss, or gravity.

The procedure involves removing excess skin, tightening surrounding tissue, and repositioning the nipples for a more lifted and symmetrical contour. A breast lift can be performed alone or combined with breast augmentation for enhanced volume or breast reduction for size adjustment.

Surgery: Typically performed under general anesthesia, the procedure takes 2-3 hours. Patients usually go home the same day.

Post-Operative Care:

First Week: Mild swelling, bruising, and discomfort are common. A surgical bra is worn to provide support and minimize swelling. Stitches are usually removed after 7-10 days, if non-dissolvable.
After 2 Weeks: Swelling begins to subside, and most patients feel comfortable returning to work and light activities.
After 4-6 Weeks: Physical activities, including exercise, can be gradually resumed. Patients should avoid heavy lifting during this time.
After 3-6 Months: Final results are visible as swelling fully resolves, and scars fade, blending into the natural contours of the breasts.
A breast lift rejuvenates the appearance of your chest, creating a youthful and proportionate silhouette while prioritizing your safety and comfort throughout the recovery process.
      `, image: './surgeriesfolder/Breast_Lift.jpg' },
    { id: 9, name: 'Gynecomastia', content: `
     Gynecomastia is a condition where excess glandular tissue or fat leads to enlarged male breasts, often caused by hormonal imbalances, genetics, certain medications, or lifestyle factors. Male breast reduction surgery effectively treats gynecomastia by removing this excess tissue to restore a more masculine chest appearance.

This procedure enhances physical comfort and confidence, and is tailored to each patient’s anatomy and goals. Liposuction may be incorporated to address fatty deposits for a smoother contour.

Surgery:
Performed under local anesthesia with sedation or general anesthesia, the procedure typically lasts 1-2 hours. Patients can return home the same day.

Post-Operative Care:

First Week: Swelling, bruising, and mild discomfort are common. A compression garment is worn to reduce swelling and support the chest during healing. Most patients can resume light activities within a few days.
After 2 Weeks: Stitches, if non-dissolvable, are removed, and swelling continues to decrease. Patients can return to work and light physical activities.
After 4-6 Weeks: Strenuous physical activities, including upper-body workouts, can gradually resume. Continued use of the compression garment is often recommended.
After 3-6 Months: Final results are visible as swelling fully resolves, and scars fade over time.
Gynecomastia surgery offers a more contoured and masculine chest appearance, helping patients achieve long-lasting results and renewed confidence. Our comprehensive care ensures a smooth and successful recovery.
      `, image: './surgeriesfolder/Gynecomastia.jpg' },
    { id: 10, name: 'Mommy Makeover', content: `
     A Mommy Makeover is a personalized combination of cosmetic procedures designed to restore and enhance a woman’s body after pregnancy, childbirth, and breastfeeding. It typically addresses areas affected by these life changes, such as the abdomen, breasts, and hips, helping to regain pre-pregnancy contours and boost confidence.

Common components of a Mommy Makeover include:

Tummy Tuck (Abdominoplasty): Removes excess skin and fat from the abdomen and tightens abdominal muscles.
Breast Surgery: Can include a breast lift, augmentation, or reduction to restore breast shape and volume.
Liposuction: Targets stubborn fat deposits for a slimmer silhouette.
Other Enhancements: Additional procedures like stretch mark treatments, thigh lifts, or vaginal rejuvenation can be included based on individual goals.
Surgery:
Performed under general anesthesia, a Mommy Makeover is customized to each patient’s needs. The duration depends on the chosen procedures, typically lasting 4-6 hours. Patients may need an overnight stay for monitoring.

Post-Operative Care:

First Week: Rest is crucial. Swelling, bruising, and mild discomfort are expected and managed with prescribed medication. Compression garments are worn to support healing.
After 2 Weeks: Stitches, if non-dissolvable, are removed. Light activities and short walks are encouraged to aid circulation.
After 4-6 Weeks: Most patients can resume non-strenuous work and light physical activities. Avoid heavy lifting or intense workouts.
After 3-6 Months: Final results are visible as swelling resolves and scars fade, revealing a firmer, more youthful appearance.
A Mommy Makeover is a transformative journey that restores confidence and helps women feel like themselves again. Each procedure is tailored to meet individual needs, ensuring a safe recovery and stunning results.
      `, image: './surgeriesfolder/Mommy_Makeover.jpg' },
    { id: 11, name: 'Abdominoplasty', content: `
     Abdominoplasty, commonly known as a tummy tuck, is a surgical procedure designed to remove excess skin and fat from the abdomen and tighten weakened or separated abdominal muscles. It restores a smoother, firmer midsection and is often sought after significant weight loss, pregnancy, or aging.

The procedure enhances abdominal contours and can address stretch marks and skin laxity below the navel. Abdominoplasty may be combined with liposuction for further refinement of the waistline.

Surgery:
Performed under general anesthesia, the procedure typically lasts 2-4 hours. Patients may require an overnight stay for observation, depending on the extent of the surgery.

Post-Operative Care:

First Week: Swelling, bruising, and mild discomfort are common. A compression garment is worn to minimize swelling and support healing. Drains may be placed to remove excess fluid and are usually removed after a few days.
After 2 Weeks: Stitches, if non-dissolvable, are removed. Patients can resume light activities, such as walking, to promote circulation.
After 4-6 Weeks: Most patients can return to work and light physical activities but should avoid strenuous exercise or heavy lifting.
After 3-6 Months: Final results are apparent as swelling resolves and scars continue to fade, blending into the natural contours of the abdomen.
Abdominoplasty provides a flatter, more toned abdominal profile, helping patients regain confidence and comfort in their appearance. Our focus is on achieving natural results with a smooth recovery process.
      `, image: './surgeriesfolder/Abdominoplasty.jpg' },
    { id: 12, name: 'Liposuction', content: `
     Liposuction is a minimally invasive cosmetic procedure designed to remove stubborn fat deposits that are resistant to diet and exercise. It sculpts and reshapes areas of the body, enhancing natural contours and achieving a slimmer, more proportionate appearance.

Common treatment areas include the abdomen, hips, thighs, arms, back, chin, and neck. Liposuction is often combined with other procedures, such as abdominoplasty or body lifts, for more comprehensive results.

Surgery:
Performed under local anesthesia with sedation or general anesthesia, depending on the extent of the treatment. The procedure typically lasts 1-3 hours, and patients can return home the same day.

Post-Operative Care:

First Week: Swelling, bruising, and mild discomfort are normal. A compression garment is worn to reduce swelling and support the treated area. Light walking is encouraged to promote circulation.
After 2 Weeks: Swelling begins to subside, and most patients can return to work and light daily activities.
After 4-6 Weeks: Physical activities, including exercise, can gradually resume. Continued use of the compression garment is recommended to ensure optimal contouring.
After 3-6 Months: Final results are visible as swelling fully resolves, revealing a smoother and more defined silhouette.
Liposuction is a safe and effective way to target localized fat deposits and achieve a more sculpted look. Our approach focuses on enhancing your natural contours while ensuring a smooth recovery and lasting results.
      `, image: './surgeriesfolder/Liposuction.jpg' },
    { id: 13, name: 'Brachioplasty and Thigh Lifting', content: `
     Brachioplasty (Arm Lift)

Brachioplasty, or an arm lift, is a surgical procedure that reshapes the upper arms by removing excess skin and fat. It is ideal for individuals who experience sagging skin on the arms due to aging, significant weight loss, or genetics. The procedure tightens and contours the arms, restoring a smoother, more toned appearance.

Surgery:
Performed under general anesthesia, the procedure typically lasts 2-3 hours. Depending on the extent of the surgery, patients may go home the same day.

Post-Operative Care:

First Week: Swelling, bruising, and mild discomfort are common. Patients should wear a compression garment to support healing and reduce swelling.
After 2 Weeks: Most patients can return to light activities. Stitches, if non-dissolvable, are removed.
After 4-6 Weeks: Physical activities, including upper-body exercises, can gradually resume. Scars begin to fade.
After 3-6 Months: Final results are visible as swelling resolves and scars mature, blending into the natural contours of the arms.

Brachioplasty and thigh lifting enhance body contours, restoring confidence and comfort in your appearance. Our goal is to provide natural-looking results with a focus on safety and personalized care for a smooth recovery.
      `, image: './surgeriesfolder/Brachioplasty_and_Thigh_Lifting.jpg' },
    { id: 14, name: 'Intimate Surgery', content: `
     Intimate Surgery

Intimate surgery focuses on enhancing the aesthetics, comfort, and functionality of the genital area. It addresses individual concerns to boost confidence and improve quality of life.

Common Procedures:
Labiaplasty: Reduces or reshapes the labia minora for comfort and aesthetics.
Vaginoplasty: Tightens the vaginal canal for improved functionality and satisfaction.
Monsplasty: Reduces excess fat or skin in the pubic area for a smoother contour.
Hymenoplasty: Reconstructs the hymen for personal or cultural reasons.
Surgery: Performed under local or general anesthesia, most procedures last 1-3 hours, with same-day discharge.

Post-Operative Care:

First Week: Swelling and mild discomfort are common. Avoid tight clothing and physical strain.
After 4-6 Weeks: Full recovery, with resumption of physical and sexual activities.
Intimate surgery is discreet and personalized, designed to enhance comfort, confidence, and overall well-being.
      `, image: './surgeriesfolder/Intimate_Surgery.jpg' },

    
    { id: 19, name: 'Bichectomy', content: `
     Bichectomy is a minimally invasive procedure that enhances facial contours by removing excess fat from the buccal fat pads in the cheeks. This results in a slimmer, more defined look, emphasizing the natural cheekbones and jawline.

Ideal Candidates:

Individuals with rounded or fuller cheeks seeking a more sculpted facial appearance.
Patients in good health with realistic expectations.
Surgery:
Performed under local anesthesia, the procedure lasts about 30-60 minutes. Patients can return home the same day.

Post-Operative Care:

First Week: Mild swelling and discomfort are normal. A soft diet and good oral hygiene are recommended.
After 2 Weeks: Swelling subsides significantly, and normal activities can be resumed.
After 3-6 Months: Final results become visible as the face fully heals and contours settle.
Bichectomy is a quick, effective way to achieve a refined and balanced facial profile, with minimal downtime and long-lasting results.
      `, image: './surgeriesfolder/Bichectomy.jpg' },
    { id: 20, name: 'Scar Correction', content: `
     Scar correction, or scar revision surgery, is a procedure designed to improve the appearance of scars caused by injuries, surgeries, or other skin conditions. The goal is to minimize the scar’s visibility and blend it with the surrounding skin for a more natural look.

Types of Scar Treatments:
Surgical Revision: Removes or repositions the scar for a less noticeable appearance.
Laser Therapy: Reduces discoloration and smooths scar texture.
Dermabrasion or Chemical Peels: Improves the skin’s surface by smoothing irregularities.
Steroid Injections: Flattens raised scars, such as keloids or hypertrophic scars.
Procedure:
Scar correction can be performed under local or general anesthesia, depending on the scar's size and location. Duration varies based on the technique used.

Post-Operative Care:

First Week: Swelling, redness, and mild discomfort are common. Patients should keep the area clean and follow the surgeon's care instructions.
After 4-6 Weeks: The scar begins to fade and flatten as the skin heals.
After 6 Months to 1 Year: Final results are visible as the scar matures and blends with surrounding skin.
Scar correction helps restore confidence by improving the scar’s appearance while maintaining skin integrity. Treatment plans are personalized for optimal results.
      `, image: './surgeriesfolder/Scar_Correction.jpg' },
    { id: 21, name: 'Reconstructive Plastic Surgery', content: `
     Reconstructive plastic surgery focuses on restoring form and function to areas of the body affected by trauma, congenital conditions, disease, or surgery. It aims to improve the patient's quality of life by addressing physical deformities and functional impairments.

Common Applications:
Post-Trauma Reconstruction: Repairs injuries from accidents, such as fractures, burns, or soft tissue damage.
Cancer Reconstruction: Includes breast reconstruction after mastectomy or facial reconstruction following tumor removal.
Congenital Defects: Corrects birth conditions like cleft lip and palate or syndactyly (webbed fingers).
Scar Revision and Skin Grafts: Treats scars or replaces damaged skin with healthy tissue.
Surgery:
Performed under general or local anesthesia, depending on the complexity of the procedure. Duration and recovery vary widely based on the specific reconstruction.

Post-Operative Care:

First Week: Initial swelling and discomfort are managed with medications. Patients follow specific care instructions to protect the surgical site.
After 4-6 Weeks: Most swelling resolves, and normal activities can gradually resume.
After 6 Months to 1 Year: Final results become apparent as tissues fully heal and scars mature.
Reconstructive plastic surgery not only restores physical appearance but also improves functionality and boosts confidence, helping patients regain normalcy in their lives.
      `, image: './surgeriesfolder/Reconstructive_Plastic_Surgery.jpg' },
    
    
    { id: 16, name: 'Tumores Cutaneos', content: `
     Los tumores cutáneos son lesiones que se forman en la piel y pueden ser benignos (no cancerosos) o malignos (cancerosos). Su detección y tratamiento oportuno son fundamentales para prevenir complicaciones y garantizar un resultado estético y funcional óptimo.

Tipos Comunes:
Benignos: Lipomas, quistes sebáceos, nevus (lunares).
Malignos: Carcinoma basocelular, carcinoma de células escamosas, y melanoma.
Tratamiento:

Resección Quirúrgica: Extirpación del tumor con márgenes seguros, preservando el tejido sano circundante.
Reparación Reconstructiva: Si es necesario, se realizan injertos de piel o colgajos para minimizar cicatrices y restaurar la apariencia.
Procedimiento:
Generalmente realizado bajo anestesia local. La duración depende del tamaño y la localización del tumor. En casos complejos, se puede requerir anestesia general y reconstrucción avanzada.

Cuidados Postoperatorios:

Primera Semana: Mantener el área limpia y protegida. Es común una leve inflamación.
Después de 2 Semanas: Retiro de puntos si no son absorbibles. La herida comienza a sanar completamente.
Después de 3-6 Meses: La cicatriz madura, volviéndose menos visible.
El tratamiento de tumores cutáneos busca eliminar la lesión de forma segura y eficiente, preservando tanto la función como la estética del área tratada.
      `, image: './surgeriesfolder/Tumores_Cutaneos.jpg' },
    

  ];
  